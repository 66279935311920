@import '../styles/colors';

.comic-view {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: hidden;

    // Allow transitions for sprites with IDs
    .comic-view-sprite {
        &[id] {
            transition: 250ms all;
        }
    }

    .comic-view-sprite {
        position: absolute;

        &.flip-x {
            transform: scaleX(-1);
        }
    }

    #dialogue {
        height: 100%;
        width: 100%;
        font-family: 'Balsamiq Sans';
        font-weight: 700;
        font-size: 20px;

        & > img {
            position: absolute;
            width: 100%;
            bottom: 40px;
            left: 0;
        }

        .message-container {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 12%;
            height: 15%;
            left: 85px;
            right: 85px;

            p {
                white-space: pre-wrap;
            }
        }

        .speaker-container {
            display: flex;
            position: absolute;
            top: calc(100% - 50px);
            left: 85px;
            right: 85px;

            span {
                display: block;
                padding: 8px 16px;
                border-radius: 16px;
            }
        }

        &.dialogue-left {
            img {
                transform: scaleX(-1);
            }

            .speaker-container span {
                background-color: $pink;
            }
        }

        &.dialogue-right {
            .speaker-container {
                justify-content: flex-end;
            }

            .speaker-container span {
                background-color: $blue;
            }
        }
    }

    #decision {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 40px 85px;
        background-color: $grape;

        button {
            flex: 1;
            background-color: $blue;
            border: none;
            font-family: 'Balsamiq Sans';
            font-weight: 700;
            font-size: 20px;
            padding: 16px;
            border-radius: 32px;
            margin: 0 8px;
        }
    }
}