@import '../styles/breaks';
@import '../styles/colors';

#problem-statement {
  width: 100%;
  max-width: 1200px;
}

#topic-h2 {
  font-size: 50px;
  text-shadow: 2px 2px 5px $sunshine;
}

#topic-p {
  padding-left: 80px;
  padding-right: 80px;
}

button.topic-button-selected {
  font-size: 30px;
  font-weight: bolder;
  width: auto;
  background-color: $sand;
  color: $grape;
  border-bottom: 4px solid black;
  border-radius: 0;
  padding-bottom: 5px;
}

button.topic-button {
  font-size: 30px;
  width: auto;
  background-color: $sand;
  color: #7a4988;
  border-bottom: 2px solid transparent;
}

#concepts {
  margin-bottom: 30px;
}

#width-30 {
  width: 30%
}

#width-60 {
  width: 60%
}

#caption {
  font-size: 20px;
  margin-bottom: 40px;
}