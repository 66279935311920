@import '../styles/breaks';
@import '../styles/colors';

#strawberry-welcome {
    width: 700px;
}

.parents-of-marine-rescuers-section {
    position: relative;

    img {
        width: 100%;
    }

    h1 {
        padding-top: 0px;
        color: $grape;
    }

    .mega-button {
        position: absolute;
        width: 40%;
        top: 50%;
        left: 30%;
        background-color: $sunshine;
        color: $grape;
        font-size: 40px;
        padding: 12px 24px;
        border-radius: 20px;
        text-align: center;
        text-decoration: none;
    }
}








