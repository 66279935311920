@import 'styles/breaks';
@import 'styles/colors';

.marketing-page {
    &, * {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: 'Balsamiq Sans';
    }
    
    a {
        color: $sand;
    }
  
    .row { // line up elements horizontally on landing pages 
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 40px;
    }
        
    .column { // line up vertically horizontally on landing pages 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
    
    }

    .container {
        background-color: $sunshine;
        color: $grape;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        border-radius: 30px;
    }
    
    .section {
        padding-bottom: 100px;
    }

    .section-footer {
        padding-bottom: 0;
    }
        
    .content {
        padding-left: 60px;
        padding-right: 60px;
        margin-bottom: 60px;
    }
    
    .width-10px {
        width: 10px;
    }
    
    .width-400px {
        width: 400px;
    }
    
    .text-center {
        text-align: center;
    }
    
    .background-color-grape {
        background-color: $grape;
    }
    
    .background-color-sand {
        background-color: $sand;
    }
    
    .color-sunshine{
        color: $sunshine;
    }
    
    .color-sand{
        color: $sand;
    }
    
    .color-grape{
        color: $grape;
    }
    
    .color-blue{
        color: $blue;
    }
    
    h1 {
        font-size: 60px;
        padding-top: 60px;        
    
    }
    
    h2 {
        font-size: 40px;
        padding: 30px;        
        padding-left: 80px;
    } 
    
    p {
        font-size: 30px;
        padding: 30px;
    }

    li {
        padding-left: 30px;
        padding-bottom: 20px;
        font-size: 30px;
    }

    .height-300px {
        height: 300px;
        align-items: center;
    }

    .row-no-padding { 
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    main {
        margin: 0;
        font-family: 'Balsamiq Sans';
        background-color: $blue;
    }

    .reponsive {
        max-width: 100%;
        height: auto;
        width: auto;
    }

    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
