@import './styles/breaks';
@import './styles/colors';

// button styling source: https://www.w3schools.com/howto/howto_css_round_buttons.asp

.marketing-page button,
.std-btn {
  background-color: $blue;
  border: none;
  color: white;
  width: 180px;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 30px 2px;
  border-radius: 30px;
  font-family : inherit;
  cursor: pointer;
  transition: 250ms background-color;

  &.sunshine {
    background-color: $sunshine;

    &:hover {
      background-color: darken($sunshine, 10);
    }
  }
}
